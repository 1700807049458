import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
  createPaystubReportFile,
  emailGroupedPaystubs,
  emailPaystubReport,
  emailPaystubReportToAll,
} from "../../services/reportServices";
import { showToast } from "../../utils/toastUtils";
import { printPreviewReportWithBase64 } from "../../utils/payrollRunUtils";
import moment from "moment";

function PaystubReportMenuModal({
  modal,
  toggle,
  payrollRunLineItems,
  startDate,
  endDate,
  payrollRunId,
  args,
}) {
  const accountId = useSelector((state) => state.auth.user.accountId);
  const settingsState = useSelector((state) => state.settings);
  const email = useSelector((state) => state.auth.user.email);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );

  const sendPaystubReportToAll = async () => {
    const payload = {
      accountId:
        selectedAccountState.accountId === null
          ? accountId
          : selectedAccountState.accountId,
      payrollRunId,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      subject: "Paystub Report",
      body: "Report attached here",
      attachmentFileName: "Paystub Report",
    };

    const response = await emailPaystubReportToAll(payload);
    const data = await response.json();

    if (!_.isNil(data.errorMessage)) {
      showToast({
        type: "error",
        message: data.errorMessage,
      });
      return;
    }

    showToast({
      type: `${response.ok ? "success" : "error"}`,
      message: `${response.ok ? "Successfully emailed" : "Failed email"
        } paystub report to all employees.`,
    });
  };

  const sendGroupedPaystubs = async () => {
    const payload = {
      accountId:
        selectedAccountState.accountId === null
          ? accountId
          : selectedAccountState.accountId,
      payrollRunId,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      toEmail: settingsState.accountSettings.payrollNotificationEmail || email,
      subject: "Grouped Paystub Report",
      body: "Report attached here",
      attachmentFileName: "Grouped Paystub Report",
    };

    const response = await emailGroupedPaystubs(payload);
    const data = await response.json();

    if (!_.isNil(data.errorMessage)) {
      showToast({
        type: "error",
        message: data.errorMessage,
      });
      return;
    }

    showToast({
      type: `${response.ok ? "success" : "error"}`,
      message: `${response.ok ? "Successfully emailed" : "Failed to email"
        } grouped paystubs report.`,
    });
  };

  const sendPaystubReport = async (payrollRunLineItem) => {
    const payload = {
      payrollRunLineItemId: payrollRunLineItem.payrollRunLineItemId,
      payrollRunId: payrollRunId,
      accountId:
        selectedAccountState.accountId === null
          ? accountId
          : selectedAccountState.accountId,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      toEmail: payrollRunLineItem.user.email,
      subject: "Paystub Report",
      body: "Report attached here",
      attachmentFileName: "Paystub Report",
    };

    const response = await emailPaystubReport(payload);
    const data = await response.json();

    if (!_.isNil(data.errorMessage)) {
      showToast({
        type: "error",
        message: data.errorMessage,
      });
      return;
    }

    showToast({
      type: `${response.ok ? "success" : "error"}`,
      message: `${response.ok ? "Successfully emailed" : "Failed email"
        } paystub report to ${payrollRunLineItem.userName}.`,
    });
  };

  const printPaystubReport = async (payrollRunLineItem) => {
    const payload = {
      payrollRunLineItemId: payrollRunLineItem.payrollRunLineItemId,
      payrollRunId: payrollRunId,
      accountId:
        selectedAccountState.accountId === null
          ? accountId
          : selectedAccountState.accountId,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    };

    const response = await createPaystubReportFile(payload);
    const data = await response.json();

    if (!_.isNil(data.errorMessage)) {
      showToast({
        type: "error",
        message: data.errorMessage,
      });
      return;
    }

    printPreviewReportWithBase64(data.base64);
  };

  return (
    <Modal isOpen={modal} toggle={toggle} {...args}>
      <ModalHeader toggle={toggle}>Paystub Report Menu</ModalHeader>
      <ModalBody>
        <div className="modal-header-container d-flex flex-row-reverse align-items-center">
          <button
            className="btn btn-md btn-primary ms-2 w-50"
            onClick={() => sendPaystubReportToAll()}
          >
            Email to All Employees
          </button>

          <button
            className="btn btn-md btn-primary me-2 w-50"
            onClick={() => sendGroupedPaystubs()}
          >
            Email Grouped Paystubs
          </button>
        </div>

        <div className="content-body-container">
          <div className="row">
            {(payrollRunLineItems.data.length > 0) ? (
              payrollRunLineItems.data
                .filter(lineItem => {
                  const totalUnitsOfPay = lineItem.payrollRunLineItemPayItem.reduce((sum, payItem) => {
                    const valueToAdd = payItem.payMethodType === 0 ? 1 : payItem.unitsOfPay;
                    return sum + valueToAdd;
                  }, 0);
                  

                  const totalPayPerUnit = lineItem.payrollRunLineItemPayItem.reduce((sum, payItem) => {
                    return sum + payItem.payPerUnit;
                  }, 0);
                  return totalUnitsOfPay > 0 && totalPayPerUnit > 0;
                })
                .map((payrollRunLineItem, index) => (
                  <div key={index} className="col-12 mb-3">
                    <div className="card shadow-sm border-0">
                      <div className="card-body py-1">
                        <div className="d-flex align-items-center">
                          <div className="me-auto p-2">
                            <span className="fw-bold me-3">{index + 1}.</span>
                            &nbsp;{payrollRunLineItem.userName}
                          </div>
                          <div className="p-1">
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() =>
                                sendPaystubReport(payrollRunLineItem)
                              }
                            >
                              <i className="fa-solid fa-envelope"></i>
                            </button>
                          </div>
                          <div className="p-1">
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() =>
                                printPaystubReport(payrollRunLineItem)
                              }
                            >
                              <i className="fa-solid fa-file-export"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <div className="col-12 mb-3">No employees available</div>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default PaystubReportMenuModal;
